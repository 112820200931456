var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b3b836a45c7b35058b45a58fbe880c0ac99d0284"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import {
    ExtraErrorData as ExtraErrorDataIntegration,
    CaptureConsole as CaptureConsoleIntegration,
} from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const IS_NOT_PRODUCTION = process.env.NODE_ENV !== 'production'

Sentry.init({
    dsn: SENTRY_DSN || 'https://ac562ae7aa044d40b1265c2a96c9c70a@o542074.ingest.sentry.io/5661209',
    tracesSampleRate: IS_NOT_PRODUCTION ? 1.0 : 0.01,
    replaysSessionSampleRate: IS_NOT_PRODUCTION ? 1.0 : 0.01,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
        'Amplitude Logger [Error]: TypeError: Load failed',
        'Amplitude Logger [Error]: TypeError: Failed to fetch',
        'Amplitude Logger [Error]: TypeError: cancelled',
        'TypeError: Load failed',
        'Moengage Web SDK loading failed.',
        "TypeError: null is not an object (evaluating 't.currentSource=e')",
        "TypeError: null is not an object (evaluating 'e.s_e_b_e')",
        'TypeError: Right side of assignment cannot be destructured',
    ],
    integrations: [
        new ExtraErrorDataIntegration({}),
        new CaptureConsoleIntegration({
            levels: ['error'],
        }),
    ],
})
